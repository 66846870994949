import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { formatAddress } from "../utils";
import { Task } from "../interfaces";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from "@mui/material";

const statusColorMap: { [key: string]: string } = {
  COMPLETED: "#4cd137",
  ACTIVE: "yellow",
};

const blockExplorerUrl = (taskId: string) =>
  `https://explorer.iex.ec/bellecour/task/${taskId}`;

interface ClaimItemProps {
  tasks: Task[];
}

const formatDate = (timestamp: string) => {
  const date = new Date(parseInt(timestamp) * 1000);
  return date.toLocaleDateString();
};

const DarkTableContainer = styled(TableContainer)({
  backgroundColor: "#333",
});

const StyledTable = styled(Table)({
  minWidth: "100%",
  display: "flex",
  flexDirection: "column",
  border: "none", // Remove table border
});

const StyledTableRow = styled(TableRow)({
  display: "flex",
  width: "100%",
  border: "none", // Remove row border
});

const DarkTableRow = styled(TableRow)({
  backgroundColor: "#424242",
  display: "flex",
  width: "100%",
  border: "none", // Remove row border
});

const StyledTableCell = styled(TableCell)({
  color: "#ffffff",
  padding: "8px",
  flex: 1,
  border: "none", // Remove cell border
});

const DarkTableCell = styled(TableCell)({
  color: "#ffffff",
  padding: "5px",
  flex: 1,
  border: "none", // Remove cell border
});

const TaskHistory: React.FC<ClaimItemProps> = ({ tasks }) => {
  const [activeTaskStatuses, setActiveTaskStatuses] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTaskStatuses((prevState) => {
        const newState = { ...prevState };
        tasks.forEach((task) => {
          if (task.status === "ACTIVE") {
            newState[task.id] = (newState[task.id] + 1 || 0 + 1) % 4;
          } else if (newState[task.id] !== undefined) {
            delete newState[task.id]; // Remove task if it's not active
          }
        });
        return newState;
      });
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, [tasks]);

  return (
    <DarkTableContainer>
      <StyledTable aria-label="task table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Task</StyledTableCell>
            <StyledTableCell>Time</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {tasks.map((task, index) => {
            const statusColor = statusColorMap[task.status] || "gray";
            const activeTaskStatus = activeTaskStatuses[task.id] || 0;
            const statusText =
              task.status === "ACTIVE"
                ? `ACTIVE${".".repeat(activeTaskStatus + 1)}`
                : task.status;
            return (
              <DarkTableRow key={index}>
                <DarkTableCell>
                  <Link
                    href={blockExplorerUrl(task.id)}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                    sx={{ color: "white", "&:hover": { color: "whitesmoke" } }}
                  >
                    {formatAddress(task.id, 5, 5)}
                  </Link>
                </DarkTableCell>
                <DarkTableCell>{formatDate(task.timestamp)}</DarkTableCell>
                <DarkTableCell sx={{ color: statusColor }}>
                  {statusText}
                </DarkTableCell>
              </DarkTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </DarkTableContainer>
  );
};

export default TaskHistory;
