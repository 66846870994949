import { providers } from "ethers";
import { MAINNET_PUBLIC_RPC_URL } from "../constants";

//function to get the address associated to an ens
export const resolveENS = async (ensName: string): Promise<string | null> => {
  try {
    const provider = new providers.JsonRpcProvider(MAINNET_PUBLIC_RPC_URL);
    const resolvedAddress = await provider.resolveName(ensName);
    return resolvedAddress;
  } catch (error) {
    console.error("Error resolving ENS name:", error);
    return null;
  }
};
