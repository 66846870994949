import React, { useState } from "react";
import styled from "styled-components";
import {
  Typography,
  Button,
  CircularProgress as Spinner,
  Modal,
  IconButton,
  Box,
  Link,
} from "@mui/material";
import { CheckCircle, ExpandMore, ExpandLess } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { ethers } from "ethers";
import { formatAddress } from "../utils";

// Styled components for the main container and cards
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  text-align: left; // Ensure text alignment is left
`;

const AddressCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 100%;
  background-color: #333; // Darker background color
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
`;

const AddressText = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`;

const EligibleText = styled(Typography)`
  font-size: 1.3rem; // Increased font size
`;

const CongratulatoryMessage = styled(Typography)`
  color: white;
  margin-bottom: 24px;
  text-align: center;
`;

const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
  color: white;
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e1e1e; // Dark background to match the component
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #2a2a2a; // Slight border to match component styling
  margin-top: 8px; // Add some space above the container
`;

const AmountText = styled.div`
  display: flex;
  flex-direction: column;
  color: #4caf50; // Green color for claimed amount
`;

const AmountLabel = styled.span`
  color: #b3b3b3; // Grey color for text
`;

const AmountValue = styled.span`
  color: #4caf50; // Green color for amount
`;
// Modal style
const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #141414;
  border: 1px solid #ccc; /* Added border */
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface EligibilityResultsModalProps {
  open: boolean;
  onClose: () => void;
  claimableAmounts: {
    [address: string]: { amount: string; watchOnly: boolean };
  };
  notEligible: string[];
  handleClaim: (address: string) => Promise<string | undefined>;
  isClaiming: string | null;
  app: {
    token: {
      decimals: number;
      ticker: string;
    };
  };
}

const EligibilityResultsModal: React.FC<EligibilityResultsModalProps> = ({
  open,
  onClose,
  claimableAmounts,
  notEligible,
  handleClaim,
  isClaiming,
  app,
}) => {
  const [showNotEligible, setShowNotEligible] = useState(false);
  const [claimInProgress, setClaimInProgress] = useState(false);
  const [hasClaimed, setHasClaimed] = useState<{ [address: string]: boolean }>(
    {}
  );
  const [claimTaskId, setClaimTaskId] = useState<string | null>(null);
  const eligibleCount = Object.keys(claimableAmounts).length;

  const toggleNotEligible = () => {
    setShowNotEligible(!showNotEligible);
  };

  const wrappedHandleClaim = async (address: string) => {
    setClaimInProgress(true);
    try {
      const taskId = await handleClaim(address);
      if (taskId === undefined) {
        alert("error during the task initialization");
        return;
      }
      setClaimTaskId(taskId);
      setHasClaimed((prev) => ({ ...prev, [address]: true })); // Update the hasClaimed state
    } catch (error) {
      console.error("Error during claim:", error);
    } finally {
      setClaimInProgress(false);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <ModalBox>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
          <MainContainer>
            {eligibleCount > 0 && (
              <>
                <CongratulatoryMessage variant="h5">
                  🎉 Congratulations! You have {eligibleCount} address
                  {eligibleCount > 1 ? "es" : ""} eligible for the airdrop.
                </CongratulatoryMessage>
                <br />
              </>
            )}
            {Object.keys(claimableAmounts).map((address) => (
              <AddressCard key={address}>
                <AddressText>
                  <EligibleText variant="body1">
                    <CheckCircle
                      style={{
                        verticalAlign: "middle",
                        marginRight: 8,
                        color: "#4cd137",
                      }}
                    />
                    {address}
                  </EligibleText>
                  <AmountContainer>
                    <AmountText>
                      <AmountLabel>Claimed/Total</AmountLabel>
                      <AmountValue>
                        {0}/
                        {ethers.utils.formatUnits(
                          claimableAmounts[address].amount,
                          app.token.decimals
                        )}{" "}
                        ${app.token.ticker}
                      </AmountValue>
                    </AmountText>
                  </AmountContainer>
                </AddressText>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => wrappedHandleClaim(address)}
                  disabled={
                    isClaiming === address ||
                    claimInProgress ||
                    hasClaimed[address]
                  }
                  style={{
                    marginLeft: "16px",
                    color: "white",
                    backgroundColor:
                      isClaiming === address ||
                      claimInProgress ||
                      hasClaimed[address]
                        ? "#555"
                        : "#4caf50",
                    borderRadius: "8px",
                    padding: "8px 16px",
                    cursor:
                      isClaiming === address ||
                      claimInProgress ||
                      hasClaimed[address]
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  {isClaiming === address || claimInProgress ? (
                    <Spinner
                      size={24}
                      style={{ marginRight: 8, color: "white" }}
                    />
                  ) : claimableAmounts[address].watchOnly ? (
                    "Import to Snap"
                  ) : (
                    "Claim"
                  )}
                </Button>
              </AddressCard>
            ))}
            <ToggleButtonContainer onClick={toggleNotEligible}>
              <Typography
                variant="body2"
                style={{ marginRight: 8, textAlign: "left" }}
              >
                Uneligible addresses
              </Typography>
              {showNotEligible ? (
                <ExpandLess style={{ color: "white" }} />
              ) : (
                <ExpandMore style={{ color: "white" }} />
              )}
            </ToggleButtonContainer>
            {showNotEligible &&
              notEligible.length > 0 &&
              notEligible.map((address) => (
                <Typography
                  key={address}
                  variant="body2"
                  component="span"
                  style={{ color: "white" }}
                >
                  {formatAddress(address)}
                  {notEligible.indexOf(address) !== notEligible.length - 1}
                </Typography>
              ))}
          </MainContainer>
        </ModalBox>
      </Modal>

      <Modal open={claimInProgress}>
        <ModalBox>
          <Typography variant="h6">
            Task is being initialized on iExec, please do not refresh the page
          </Typography>
          <Spinner size={24} style={{ marginTop: 16, color: "white" }} />
        </ModalBox>
      </Modal>

      <Modal open={claimTaskId !== null && !claimInProgress}>
        <ModalBox>
          <Typography variant="h6">Claim Completed</Typography>
          <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              Task ID:
            </Typography>
            <Link
              href={`https://explorer.iex.ec/bellecour/task/${claimTaskId}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="body2"
              color="primary"
              sx={{ wordBreak: "break-all" }}
            >
              {claimTaskId}
            </Link>
          </Box>
          <Typography variant="body2" style={{ marginTop: 16 }}>
            You can check the task details on the block explorer or in the
            sidebar under your address.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setClaimTaskId(null)}
            style={{ marginTop: 16 }}
          >
            Close
          </Button>
        </ModalBox>
      </Modal>
    </>
  );
};

export default EligibilityResultsModal;
