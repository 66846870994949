import React, { useState } from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import EligibilityResultsModal from "./EligibilityResultsModal";
import { useEligibilityData } from "../hooks/useEligibilityData";
import {
  ensureSnapInstalled,
  handleWatchOnlyAccount,
  processClaim,
  getTierByName,
} from "../utils";
import { Airdrop } from "../interfaces";
import AppDetails from "./AppDetails";

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
  background-color: #1e1e1e;
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;

  @media (max-width: 600px) {
    width: 95%;
    max-width: none;
    padding: 20px;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface AppModalProps {
  app: Airdrop;
  open: boolean;
  handleClose: () => void;
  addresses: { watchOnly: string[]; fromSnap?: string[] };
  onImportAccounts: (addresses: string[]) => void;
}

const AppModal: React.FC<AppModalProps> = ({
  app,
  open,
  handleClose,
  addresses,
  onImportAccounts,
}) => {
  const {
    claimableAmounts,
    claimableAmountsSetup,
    notEligible,
    tier,
    setClaimableAmountsSetup,
  } = useEligibilityData(app, addresses);
  const [isClaiming, setIsClaiming] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClosing = () => {
    setIsClaiming(null);
    setClaimableAmountsSetup(false);
    handleClose();
  };

  const handleClaim = async (
    eligibleAddress: string
  ): Promise<string | undefined> => {
    setIsClaiming(eligibleAddress);

    try {
      await ensureSnapInstalled();
      if (claimableAmounts[eligibleAddress]?.watchOnly) {
        await handleWatchOnlyAccount(onImportAccounts);
      }
      const tierToUse = getTierByName(
        tier,
        claimableAmounts[eligibleAddress].tier
      );

      if (tierToUse === undefined) {
        throw new Error(
          "Tier not found please contact the project in charge of the  airdrop"
        );
      }
      const taskId = await processClaim(
        eligibleAddress,
        app,
        tierToUse,
        claimableAmounts
      );
      return taskId;
    } catch (error) {
      console.error("Error during claim process:", error);
      alert("An error occurred during the claim process. Please try again.");
    } finally {
      setIsClaiming(null);
    }
  };

  const checkEligibility = async () => {
    /*if (addresses?.fromSnap && !(await detectRingSignatureSnap())) {
      await installSnap();
    }

    let cpt = 0;
    while (!claimableAmountsSetup) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      cpt++;
      if (cpt > 10) {
        alert(
          "Cannot check eligibility: cannot connect to IPFS. Please try again later."
        );
        return;
      }
    }

    const fromSnap = (addresses?.fromSnap || []).map((address) =>
      address.toLowerCase()
    );
    const snapAddressesToCheck = Object.keys(claimableAmounts).filter(
      (address) => fromSnap.includes(address.toLowerCase())
    );

    /*let keyImages = [];
    try {
      keyImages = await exportKeyImages(
        snapAddressesToCheck,
        app.claimContract
      );
      // TODO call the smart contract to check if the user has already claimed the airdrop.
    } catch (error) {
      console.log(error);
      }*/

    setIsModalOpen(true);
  };

  return (
    <Modal
      open={open}
      onClose={handleClosing}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalBox>
        <CloseButtonContainer>
          <IconButton onClick={handleClosing} color="inherit">
            <CloseIcon />
          </IconButton>
        </CloseButtonContainer>
        <AppDetails app={app} checkEligibility={checkEligibility} />
        <EligibilityResultsModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          claimableAmounts={claimableAmounts}
          notEligible={notEligible}
          handleClaim={handleClaim}
          isClaiming={isClaiming}
          app={app}
        />
      </ModalBox>
    </Modal>
  );
};

export default AppModal;
