import styled from "styled-components";
import { Box, Button, TextField, Typography } from "@mui/material";

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 350px;
  background-color: #141414;
  border: 1px solid #ccc; /* Added border */
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  position: relative;

  h2 {
    margin: 0;
    font-size: 14px; /* Adjust this value to resize the font */
  }

  button {
    position: absolute;
    right: 0;
  }
`;

export const Logo = styled.img`
  display: block;
  margin: 20px auto;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

export const Section = styled.div`
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  position: relative;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
`;

export const ErrorMessage = styled(Typography)`
  color: #ff6b6b;
  margin-top: 10px;
`;

export const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    color: #fff;
    background-color: #333;
    border-radius: 4px;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: #fff;
    }
    &:hover fieldset {
      border-color: #ccc;
    }
    &.Mui-focused fieldset {
      border-color: #fff;
    }
  }
  .MuiInputLabel-root {
    color: #fff;
  }
  .MuiInputLabel-root.Mui-focused {
    color: #fff;
  }
`;

export const CustomButton = styled(Button)`
  display: flex;
  align-items: center; /* Center align items */
  gap: 10px;
  background-color: #333 !important;
  color: white !important;
  text-transform: none !important;
  width: 100%;
  padding: 12px 15px !important; /* Adjust padding for button size */
  border-radius: 8px !important; /* Rounded corners */

  &:hover {
    background-color: #444 !important;
  }

  svg {
    font-size: 24px;
  }

  .icon {
    order: 1; /* Ensure icon is first */
  }

  .text {
    order: 2; /* Ensure text is second */
    flex-grow: 1; /* Make text take the remaining space */
  }
`;

export const BackButton = styled(Button)`
  color: #fff;
  &:hover {
    color: #ccc;
  }
`;

export const CustomAddButton = styled(Button)`
  ${({ disabled }) =>
    disabled
      ? `
    background-color: #000;
    color: #fff;
    &:hover {
      background-color: #000;
      color: #fff;
    }
  `
      : `
    background-color: #fff;
    color: #000;
    &:hover {
      background-color: #ccc;
    }
  `}
`;
