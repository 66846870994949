import { IpfsData } from "../interfaces";

export function getClaimableAmount(
  addresses: { watchOnly: string[]; fromSnap?: string[] | null },
  ipfsData: IpfsData
): {
  eligible: {
    [address: string]: { amount: string; tier: string; watchOnly: boolean };
  };
  notEligible: string[];
} {
  const claimableAmount: {
    [address: string]: { amount: string; tier: string; watchOnly: boolean };
  } = {};

  const addressesToLookFor: { watchOnly: string[]; fromSnap: string[] } =
    addresses.fromSnap
      ? { watchOnly: addresses.watchOnly, fromSnap: addresses.fromSnap }
      : { watchOnly: addresses.watchOnly, fromSnap: [] };

  ipfsData.data.forEach(
    (tier: {
      tierName: string;
      amountAirdropped: string;
      users: {
        address: string;
        publicKey: string; // eth compressed pubkey
      }[];
    }) => {
      if (
        addressesToLookFor.fromSnap.length === 0 &&
        addressesToLookFor.watchOnly.length === 0
      )
        return;
      // find watch only addresses and from snap addresses
      const watchOnlyAddresses = addressesToLookFor.watchOnly.map((address) =>
        address.toLowerCase()
      );
      const fromSnapAddresses = addressesToLookFor.fromSnap.map((address) =>
        address.toLowerCase()
      );

      // quick sort the users array by address
      const list = tier.users; // quicksort(tier.users);

      list.forEach((user) => {
        if (watchOnlyAddresses.includes(user.address.toLowerCase())) {
          // todo: optimize
          claimableAmount[user.address] = {
            amount: tier.amountAirdropped,
            tier: tier.tierName,
            watchOnly: true,
          };

          addressesToLookFor.watchOnly = addressesToLookFor.watchOnly.filter(
            (address) => address.toLowerCase() !== user.address.toLowerCase()
          );
        } else if (fromSnapAddresses.includes(user.address.toLowerCase())) {
          claimableAmount[user.address] = {
            amount: tier.amountAirdropped,
            tier: tier.tierName,
            watchOnly: false,
          };
          addressesToLookFor.fromSnap = addressesToLookFor.fromSnap.filter(
            (address) => address.toLowerCase() !== user.address.toLowerCase()
          );
        }
      });
    }
  );

  const notEligible = [
    ...addressesToLookFor.watchOnly,
    ...addressesToLookFor.fromSnap,
  ];

  return { eligible: claimableAmount, notEligible };
}
