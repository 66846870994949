export const getStatus = (startDate: number, endDate: number): string => {
  const now = Date.now() / 1000;
  if (now < startDate) {
    return 'Not Open';
  } else if (now > endDate) {
    // console.log('Closed')
    return 'Closed';
  } else {
    // console.log('Live')
    return 'Live';
  }
};