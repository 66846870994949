import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Link,
  Button,
} from "@mui/material";
import {
  TagsContainer,
  Tag,
  HorizontalLine,
  BottomRightButtonContainer,
} from "./style";
import { formatAddress, formatIPFSUrl } from "../utils/formatUtils";
import { Airdrop } from "../interfaces";

interface AppDetailsProps {
  app: Airdrop;
  checkEligibility: () => void;
}

const AppDetails: React.FC<AppDetailsProps> = ({ app, checkEligibility }) => (
  <Grid container spacing={2} style={{ width: "100%" }}>
    <Grid item xs={12} md={8}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <img
            src={app.imageUrl}
            alt={`${app.name} icon`}
            style={{ height: "40px", width: "40px" }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h4">{app.name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" sx={{ color: "#4cd137" }}>
            Live
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {new Date(app.startDate * 1000).toLocaleDateString()} -{" "}
            {new Date(app.endDate * 1000).toLocaleDateString()}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={8}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <TagsContainer>
            {app.tags.map((tag) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </TagsContainer>
        </Grid>
        <Grid item>
          <Typography variant="body2" component="div">
            {app.fullDescription}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" component="div">
            Token: {app.token.name} ({app.token.ticker})
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <HorizontalLine />
      </Grid>
      <Grid item xs={12}>
        <Card
          sx={{
            backgroundColor: "#2c2c2c",
            color: "#fff",
            padding: 2,
            marginBottom: 2,
          }}
        >
          <CardContent>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              <strong>Chain ID:</strong> {app.chainId}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              <strong>Claim Contract:</strong>{" "}
              {formatAddress(app.claimContract, 10, 10)}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              <strong>Protected Data Address:</strong>{" "}
              {formatAddress(app.protectedDataAddress, 10, 10)}
            </Typography>
            <Typography variant="body2">
              <strong>IPFS Data:</strong>{" "}
              <Link
                href={app.ipfsData}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: "white",
                  textDecoration: "underline",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {formatIPFSUrl(app.ipfsData)}
              </Link>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <BottomRightButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={checkEligibility}
          >
            Check eligibility
          </Button>
        </BottomRightButtonContainer>
      </Grid>
    </Grid>
  </Grid>
);

export default AppDetails;
