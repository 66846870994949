// Utility function to format address
export const formatAddress = (
  address: string,
  start?: number,
  end?: number
) => {
  if (!address) return "";
  return `${address.slice(0, start ?? 10)}...${address.slice(-(end ?? 10))}`;
};

export function formatIPFSUrl(url: string): string {
  return url.substring(0, 10) + "...." + url.substring(url.length - 10);
}
