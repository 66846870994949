import React, { useState, useEffect } from "react";
import { IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import styled from "styled-components";

import { fetchTasks } from "../utils";
import { TaskMapped } from "../interfaces";
import TaskHistory from "./TaskHistory";

const AddressListContainer = styled.div<{ show: boolean; expanded: boolean }>`
  width: ${({ expanded }) => (expanded ? "350px" : "40px")};
  background-color: #0f0f0f;
  padding: ${({ expanded }) => (expanded ? "20px" : "20px 5px")};
  margin-right: ${({ expanded }) =>
    expanded ? "0" : "20px"}; // Adjust margin when collapsed

  border-left: 1px solid #333;
  overflow-y: auto;
  transition: all 0.3s ease;

  @media (max-width: 600px) {
    width: 100%;
    border-left: none;
    border-top: 1px solid #333;
    display: ${({ show }) => (show ? "block" : "none")};
  }

  & > div:first-of-type {
    margin-top: 20px;
  }
`;
const Header = styled.div<{ expanded: boolean }>`
  display: ${({ expanded }) => (expanded ? "flex" : "none")};
  justify-content: space-between;
  align-items: center; /* Align items vertically center */
  margin-bottom: 20px;
  position: relative;

  h2 {
    margin: 0;
    font-size: 24px;
  }

  > button {
    position: relative; /* Adjusted position for alignment */
  }
`;

const AddressItem = styled.div`
  background: linear-gradient(145deg, #2a2a2a, #3a3a3a);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
`;

const AddressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  font-size: 14px;
  word-break: break-all;
`;

const ExpandButton = styled(IconButton)`
  padding: 0 !important;
  color: white !important;
`;

const StyledIconButton = styled(IconButton)`
  padding: 4px !important;
  color: #ff4757 !important;
  background-color: rgba(255, 71, 87, 0.1) !important;
  border-radius: 5px !important;
  transition: background-color 0.3s ease !important;

  &:hover {
    background-color: rgba(255, 71, 87, 0.2) !important;
  }
`;

const ClaimsList = styled.div`
  margin-top: 15px;
  padding-left: 0;
`;

const ToggleExpandButton = styled(IconButton)<{ expanded: boolean }>`
  position: absolute;
  left: ${({ expanded }) => (expanded ? "-25px" : "5%")};
  top: 50%;
  transform: translateY(-50%);
  background-color: #1e1e1e;
  color: white;
  border: 2px solid #444;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  z-index: 10;

  &:hover {
    background-color: #333;
  }

  svg {
    font-size: 24px;
    fill: white;
  }
`;

interface AddressListProps {
  addresses: {
    watchOnly: string[];
    fromSnap?: string[];
  };
  handleRemoveImportedAccounts: (address: string) => void;
  handleOpenAddAddressModal: () => void;
  formatAddress: (address: string, start: number, end: number) => string;
  showAddressList: boolean;
}

const AddressList: React.FC<AddressListProps> = ({
  addresses,
  handleRemoveImportedAccounts,
  handleOpenAddAddressModal,
  formatAddress,
  showAddressList,
}) => {
  const [expandedItems, setExpandedItems] = useState<{
    [key: string]: boolean;
  }>({});
  const [isExpanded, setIsExpanded] = useState(true);
  const [latestClaims, setLatestClaims] = useState<TaskMapped[]>([]);
  const toggleExpand = (address: string) => {
    setExpandedItems((prev) => ({
      ...prev,
      [address]: !prev[address],
    }));
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    async function fetchClaimList() {
      await getClaimList();
    }
    const interval = setInterval(fetchClaimList, 10000); // Refetch every 10 seconds
    return () => clearInterval(interval);
  }, [addresses]);

  async function getClaimList() {
    if (addresses === null) {
      return [];
    }
    const requesters = [...addresses.watchOnly];
    if (addresses.fromSnap) {
      requesters.concat(addresses.fromSnap);
    }

    try {
      const lastTasks = (await fetchTasks()) || [];
      setLatestClaims(lastTasks);
    } catch (error) {
      console.error("Error fetching last tasks:", error);
    }
  }

  return (
    <AddressListContainer show={showAddressList} expanded={isExpanded}>
      <ToggleExpandButton onClick={handleToggleExpand} expanded={isExpanded}>
        {isExpanded ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </ToggleExpandButton>
      <Header expanded={isExpanded}>
        <h2>Saved Addresses</h2>
        <IconButton color="success" onClick={handleOpenAddAddressModal}>
          <AddCircleIcon style={{ fontSize: "25px", color: "#4cd137" }} />
        </IconButton>
      </Header>
      <br></br>

      {isExpanded &&
        addresses?.fromSnap?.map((address: string) => (
          <AddressItem key={address}>
            <AddressHeader>
              <StyledTypography variant="body2">
                🦊 {formatAddress(address, 10, 10)}
              </StyledTypography>
              <ExpandButton onClick={() => toggleExpand(address)}>
                {expandedItems[address] ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ExpandButton>
            </AddressHeader>
            {expandedItems[address] && (
              <>
                <ClaimsList>
                  <TaskHistory
                    tasks={latestClaims
                      .filter(
                        (claim) => claim.address === address.toLowerCase()
                      )
                      .flatMap((claim) => claim.tasks)}
                  />
                </ClaimsList>
                <br></br>
                <StyledIconButton
                  color="secondary"
                  onClick={() => handleRemoveImportedAccounts(address)}
                >
                  <DeleteIcon style={{ fontSize: "18px" }} />
                </StyledIconButton>
              </>
            )}
          </AddressItem>
        ))}

      {isExpanded &&
        addresses?.watchOnly?.map((address: string) => (
          <AddressItem key={address}>
            <AddressHeader>
              <StyledTypography variant="body2">
                👁️ {formatAddress(address, 10, 10)}
              </StyledTypography>
              <ExpandButton onClick={() => toggleExpand(address)}>
                {expandedItems[address] ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ExpandButton>
            </AddressHeader>
            {expandedItems[address] && (
              <>
                <ClaimsList>
                  <TaskHistory
                    tasks={latestClaims
                      .filter(
                        (claim) => claim.address === address.toLowerCase()
                      )
                      .flatMap((claim) => claim.tasks)}
                  />
                </ClaimsList>
                <br></br>
                <StyledIconButton
                  color="secondary"
                  onClick={() => handleRemoveImportedAccounts(address)}
                >
                  <DeleteIcon style={{ fontSize: "18px" }} />
                </StyledIconButton>
              </>
            )}
          </AddressItem>
        ))}
    </AddressListContainer>
  );
};

export default AddressList;
