import { TaskMapped, SavedTask, Task } from "../interfaces";
import { getSavedTasks } from "./localStorageUtils";

const url =
  "https://thegraph.bellecour.iex.ec/subgraphs/name/bellecour/poco-v5";

const query = `
  query taskUsages($taskIDs: [String!]!) {
    tasks(where: {id_in: $taskIDs}, orderBy: timestamp, orderDirection: desc) {
      id
      status
      timestamp
    }
  }
`;

async function fetchTaskData(taskIds: string[]): Promise<Task[] | undefined> {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query,
      variables: { taskIDs: taskIds },
    }),
  });
  const data = await response.json();
  return data?.data?.tasks;
}

function mapTasksToAddresses(
  tasks: Task[],
  savedTasks: SavedTask[]
): TaskMapped[] {
  const taskMap: { [key: string]: Task[] } = {};

  savedTasks.forEach((savedTask) => {
    taskMap[savedTask.address] = [];
  });

  tasks.forEach((task) => {
    const address = savedTasks.find((savedTask) =>
      savedTask.tasks.includes(task.id)
    )?.address;

    if (address) {
      taskMap[address].push(task);
    }
  });

  return Object.entries(taskMap).map(([address, tasks]) => ({
    address,
    tasks,
  }));
}

export async function fetchTasks(): Promise<TaskMapped[] | undefined> {
  const savedTasks = getSavedTasks();
  if (!savedTasks) {
    return undefined;
  }

  const taskIds = savedTasks.flatMap((task) => task.tasks);

  const tasks = await fetchTaskData(taskIds);
  if (!tasks) {
    return undefined;
  }

  return mapTasksToAddresses(tasks, savedTasks);
}
