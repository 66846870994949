import { IDataProtector } from "@cypher-laboratory/pac-snap-sdk";
import { getWeb3Provider } from "@iexec/dataprotector";
import { Wallet } from "ethers";

export const GET_AIRDROPS_URL =
  "https://api.iexec.cypherlab.org/api/front_office/airdrops";

export const MAINNET_PUBLIC_RPC_URL = "https://eth.llamarpc.com";

export const DATA_PROTECTOR = () => {
  const wallet = Wallet.createRandom();
  const web3Provider = getWeb3Provider(wallet.privateKey);
  return new IDataProtector(web3Provider, {
    iexecOptions: { smsURL: "https://sms.scone-debug.v8-bellecour.iex.ec" },
  });
};

export const ENCLAVE_ADDRESS = "0x32a716d5CcE9Cf71FcC07CC6E254dF80d3D7A1E4";
