import React from "react";
import styled from "styled-components";
import { Airdrop } from "../interfaces";
import { getStatus } from "../utils";

export type AppCardProps = {
  app: Airdrop;
};

const Card = styled.div<{ $isEnded: boolean }>`
  background-color: #1e1e1e;
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative;
  ${({ $isEnded }) =>
    $isEnded &&
    `
    filter: grayscale(100%);
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    }
  `}
  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
`;

const CardIcon = styled.img`
  background-color: #333;
  border-radius: 50%;
  width: 60px; /* Adjust width */
  height: 60px; /* Adjust height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  @media (max-width: 600px) {
    width: 50px; /* Adjust width */
    height: 50px; /* Adjust height */
  }
`;

const CardTitle = styled.h3`
  margin: 0;
  font-size: 20px; /* Increased font-size */

  @media (max-width: 600px) {
    font-size: 18px; /* Adjusted font-size */
  }
`;

const CardDescription = styled.p`
  margin: 10px 0;
  font-size: 16px; /* Increased font-size */
  color: #b0b0b0;

  @media (max-width: 600px) {
    font-size: 14px; /* Adjusted font-size */
  }
`;

const CardTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const Tag = styled.span`
  background-color: #333;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px; /* Increased font-size */

  @media (max-width: 600px) {
    padding: 3px 7px;
    font-size: 12px; /* Adjusted font-size */
  }
`;

const StatusIcon = styled.div<{ status: string }>`
  color: ${({ status }) =>
    status === "Live"
      ? "#4caf50"
      : status === "Not Open"
      ? "#ff9800"
      : "#f44336"};
`;

export const AppCard: React.FC<AppCardProps> = ({ app }) => {
  const status = getStatus(app.startDate, app.endDate);

  return (
    <Card $isEnded={status === "Closed"}>
      <CardHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CardIcon src={app.imageUrl} alt={`${app.name} icon`} />
          <CardTitle>{app.name}</CardTitle>
        </div>
        {status === "Live" && <StatusIcon status={status}> Live</StatusIcon>}
        {status === "Not Open" && <StatusIcon status={status}>Soon</StatusIcon>}
      </CardHeader>
      <CardDescription>{app.shortDescription}</CardDescription>
      <CardTags>
        {app.tags.map((tag) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </CardTags>
    </Card>
  );
};
