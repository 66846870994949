import { GET_AIRDROPS_URL } from "../constants";
import { Airdrop } from "../interfaces";

export async function getAirdrops(): Promise<Airdrop[]> {
  try {
    const response = await fetch(GET_AIRDROPS_URL);
    if (!response.ok) {
      console.error("Failed to fetch airdrops");
      return [];
    }
    const airdrops = await response.json();
    return airdrops;
  } catch (error) {
    console.error("Failed to fetch airdrops", error);
    return [];
  }
}
