import { detectRingSignatureSnap } from "@cypher-laboratory/pac-snap-sdk";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import AddressList from "./components/AddressList";
import { AppCard } from "./components/AppCard";
import AppModal from "./components/AppModal";
import AddAddressModal from "./components/AddAddressModal";
import { Airdrop } from "./interfaces";
import { formatAddress, getAirdrops, getStatus } from "./utils";
// hardhat 1st mnemonic: project entry cook mango pause vast purse network regular mushroom divide chuckle river focus tobacco laundry galaxy tornado own online satisfy just wrap arctic

const AppContainer = styled.div`
  display: flex;
  background-color: #121212;
  color: #fff;
  min-height: 100vh;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 5px;
  }
`;

const SearchBar = styled.input`
  padding: 10px;
  border: none;
  border-radius: 5px;
  width: 70%;
  background-color: #333;
  color: #fff;
  font-size: 14px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const CategorySelector = styled.select`
  padding: 10px;
  border: none;
  border-radius: 5px;
  width: 30%;
  background-color: #333;
  color: #fff;
  font-size: 14px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const AppList = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* Adjusted for larger cards */
  gap: 40px; /* Increased gap */
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const MobileButtonContainer = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    width: 100%;
  }
`;

const App: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAirdrop, setSelectedAirdrop] = useState<Airdrop | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [addAddressModalOpen, setAddAddressModalOpen] = useState(false);
  const [airdrops, setAirdrops] = useState<Airdrop[]>([]);
  const [addresses, setAddresses] = useState<{
    watchOnly: string[];
    fromSnap?: string[];
  }>({
    watchOnly: [],
    fromSnap: [],
  });

  const [showAddressList, setShowAddressList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      if (!(await detectRingSignatureSnap())) {
        localStorage.removeItem("fromSnap_addresses");
      }

      const registeredWatchOnlyAddresses = localStorage.getItem(
        "watchOnly_addresses"
      );
      const registeredAddresses = localStorage.getItem("fromSnap_addresses");
      setAddresses({
        watchOnly: registeredWatchOnlyAddresses
          ? JSON.parse(registeredWatchOnlyAddresses)
          : [],
        fromSnap: registeredAddresses
          ? JSON.parse(registeredAddresses)
          : undefined,
      } satisfies { watchOnly: string[]; fromSnap?: string[] });

      const data = await getAirdrops();
      setAirdrops(data);
      setIsLoading(false); // Set isLoading to false after data is fetched
    })();
  }, []);

  useEffect(() => {
    // get the categories from the airdrops
    const categories = airdrops.reduce<string[]>(
      (acc, airdrop) => {
        if (!acc.includes(airdrop.category)) {
          acc.push(airdrop.category);
        }
        return acc;
      },
      ["All"]
    );

    setCategories(categories);
  }, [airdrops]);

  const handleOpenModal = (airdrop: Airdrop) => {
    setSelectedAirdrop(airdrop);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedAirdrop(null);
    setModalOpen(false);
  };

  const handleOpenAddAddressModal = () => {
    setAddAddressModalOpen(true);
    setShowAddressList(true);
  };

  const handleCloseAddAddressModal = () => {
    setAddAddressModalOpen(false);
  };

  const handleAddAddress = (address: string) => {
    handleNewWatchOnlyAddress(address);
  };

  const sortedAirdrops = [...airdrops].sort((a, b) => {
    const statusA = getStatus(a.startDate, a.endDate);
    const statusB = getStatus(b.startDate, b.endDate);

    if (statusA === "Live" && statusB !== "Live") return -1;
    if (statusA !== "Live" && statusB === "Live") return 1;
    if (statusA === "Not Open" && statusB !== "Not Open") return -1;
    if (statusA !== "Not Open" && statusB === "Not Open") return 1;
    return 0;
  });

  const filteredApps = sortedAirdrops.filter((airdrop) => {
    const matchesCategory =
      selectedCategory === "All" || airdrop.category === selectedCategory;
    const matchesSearch =
      airdrop.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      airdrop.fullDescription
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      airdrop.tags.some((tag) =>
        tag.toLowerCase().includes(searchTerm.toLowerCase())
      );
    return matchesCategory && matchesSearch;
  });

  const handleNewSnapAccounts = (snapAddresses: string[]) => {
    const newWatchOnlyAddresses = addresses?.watchOnly?.filter(
      (watchOnlyAddress) => !snapAddresses.includes(watchOnlyAddress)
    );
    setAddresses({
      watchOnly: newWatchOnlyAddresses || [],
      fromSnap: Array.from(
        new Set([...(addresses?.fromSnap || []), ...snapAddresses])
      ),
    } satisfies { watchOnly: string[]; fromSnap?: string[] });

    localStorage.setItem(
      "watchOnly_addresses",
      JSON.stringify(newWatchOnlyAddresses)
    );
    localStorage.setItem("fromSnap_addresses", JSON.stringify(snapAddresses));
  };

  const handleNewWatchOnlyAddress = (watchOnlyAddress: string) => {
    if (addresses?.fromSnap?.includes(watchOnlyAddress)) {
      return;
    }

    setAddresses({
      watchOnly: [...(addresses?.watchOnly || []), watchOnlyAddress],
      fromSnap: addresses?.fromSnap,
    } satisfies { watchOnly: string[]; fromSnap?: string[] });

    localStorage.setItem(
      "watchOnly_addresses",
      JSON.stringify([...(addresses?.watchOnly || []), watchOnlyAddress])
    );
  };

  const handleRemoveAddress = (address: string) => {
    try {
      const currentSnapAddresses = localStorage.getItem("fromSnap_addresses")
        ? JSON.parse(localStorage.getItem("fromSnap_addresses")!)
        : [];
      const newSnapAddresses = currentSnapAddresses.filter(
        (snapAddress: string) => snapAddress !== address
      );

      const currentWatchOnlyAddresses = localStorage.getItem(
        "watchOnly_addresses"
      )
        ? JSON.parse(localStorage.getItem("watchOnly_addresses")!)
        : [];
      const newWatchOnlyAddresses = currentWatchOnlyAddresses.filter(
        (watchOnlyAddress: string) => watchOnlyAddress !== address
      );

      // Update localStorage
      localStorage.setItem(
        "watchOnly_addresses",
        JSON.stringify(newWatchOnlyAddresses)
      );
      localStorage.setItem(
        "fromSnap_addresses",
        JSON.stringify(newSnapAddresses)
      );

      // Update state
      setAddresses({
        watchOnly: newWatchOnlyAddresses,
        fromSnap: newSnapAddresses,
      } satisfies { watchOnly: string[]; fromSnap?: string[] });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AppContainer id="app2">
      {/* <button onClick={() => BigInt("znvnzvknzvklnz")}>Click me</button> */}{" "}
      {/* to test sentry */}
      <MainContent>
        <MobileButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddAddressModal}
          >
            Add New Address
          </Button>
        </MobileButtonContainer>
        <Header>
          <SearchBar
            placeholder="Search by name or category"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <CategorySelector
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </CategorySelector>
        </Header>
        {isLoading ? (
          <CircularProgress sx={{ color: "whitesmoke", mt: "25%" }} />
        ) : (
          <AppList>
            {filteredApps.map((airdrop) => (
              <div key={airdrop.id} onClick={() => handleOpenModal(airdrop)}>
                <AppCard app={airdrop} />
              </div>
            ))}
          </AppList>
        )}
      </MainContent>
      <AddressList
        addresses={addresses}
        handleRemoveImportedAccounts={handleRemoveAddress}
        handleOpenAddAddressModal={handleOpenAddAddressModal}
        formatAddress={formatAddress}
        showAddressList={showAddressList}
      />
      {selectedAirdrop && (
        <AppModal
          open={modalOpen}
          handleClose={handleCloseModal}
          app={selectedAirdrop}
          addresses={addresses}
          onImportAccounts={handleNewSnapAccounts}
        />
      )}
      <AddAddressModal
        open={addAddressModalOpen}
        handleClose={handleCloseAddAddressModal}
        handleAddAddress={handleAddAddress}
        addresses={addresses}
        onImportAccounts={handleNewSnapAccounts}
        handleNewWatchOnlyAddress={handleNewWatchOnlyAddress}
      />
    </AppContainer>
  );
};

export default App;
