import {
  PAC_LSAG_Signature,
  callEnclave,
} from "@cypher-laboratory/pac-snap-sdk";
import { sortRing, Point } from "@cypher-laboratory/alicesring-lsag";
import { DATA_PROTECTOR, ENCLAVE_ADDRESS } from "../constants";
import { Airdrop, Tier } from "../interfaces";
import { getSavedTasks } from "./localStorageUtils";

export function getTierByName(
  tiers: Tier[],
  tierName: string
): Tier | undefined {
  return tiers.find((tier) => tier.tierName === tierName);
}

function getRing(tier: Tier): Point[] {
  return tier.users.map((user) => {
    return Point.deserialize(user.publicKey);
  });
}

function serializeRing(ring: Point[]): string[] {
  return ring.map((point) => {
    return point.serialize();
  });
}

function updateTaskLocalStorage(taskId: string, address: string) {
  const localStorageData = getSavedTasks();

  if (localStorageData === undefined) {
    localStorage.setItem(
      "tasks",
      JSON.stringify([{ address, tasks: [taskId] }])
    );
    return;
  }

  const userTasks = localStorageData.find((task) => task.address === address);
  if (userTasks) {
    userTasks.tasks.push(taskId);
  } else {
    localStorageData.push({ address, tasks: [taskId] });
  }
  localStorage.setItem("tasks", JSON.stringify(localStorageData));
  return;
}

export const processClaim = async (
  eligibleAddress: string,
  app: Airdrop,
  tier: Tier,
  claimableAmounts: {
    [address: string]: { amount: string; tier: string; watchOnly: boolean };
  }
): Promise<string> => {
  if (!claimableAmounts[eligibleAddress]) {
    throw new Error("Address not in local claimableAmounts object.");
  }

  const ring = serializeRing(sortRing(getRing(tier)));
  const signature = await PAC_LSAG_Signature(
    ring,
    app.claimContract,
    eligibleAddress,
    claimableAmounts[eligibleAddress].tier,
    app.chainId
  );

  if (!signature) {
    throw new Error("Failed to sign the claim.");
  }

  const protector = DATA_PROTECTOR();
  if (protector !== null) {
    const taskId = await callEnclave(
      protector,
      ENCLAVE_ADDRESS,
      signature,
      app.protectedDataAddress
    );
    console.log(`Claiming in progress. Task ID: ${taskId}`);
    updateTaskLocalStorage(taskId, eligibleAddress);
    return taskId;
  } else {
    throw new Error("Error calling the enclave.");
  }
};
