import {
  detectRingSignatureSnap,
  installSnap,
  importAccount,
  getAddresses,
} from "@cypher-laboratory/pac-snap-sdk";

//function to check if the snap is installed
export const ensureSnapInstalled = async () => {
  if (!(await detectRingSignatureSnap())) {
    await installSnap();
    if (!(await detectRingSignatureSnap())) {
      throw new Error("Cannot claim: Snap is not installed.");
    }
  }
};

//function to handle the claim process of watch only addresses
export const handleWatchOnlyAccount = async (
  onImportAccounts: (addresses: string[]) => void
) => {
  await importAccount();
  const snapAddresses: string[] = await getAddresses();
  onImportAccounts(snapAddresses);
  localStorage.setItem("fromSnap_addresses", JSON.stringify(snapAddresses));
};

// function to handle the import of a mnemonic in the snap
export const handleImportMnemonic = async (
  onImportAccounts: (addresses: string[]) => void,
  handleClose: () => void
) => {
  await ensureSnapInstalled();
  try {
    const imported = await importAccount();
    const newSnapAddresses = imported ? await getAddresses() : [];
    onImportAccounts(newSnapAddresses);
    localStorage.setItem(
      "fromSnap_addresses",
      JSON.stringify(newSnapAddresses)
    );
    handleClose();
  } catch (error) {
    console.log(error);
    alert("An error has occurred");
  }
};

// function to get the addresses in the snap
export const handleGetAddressFromSnap = async (
  onImportAccounts: (addresses: string[]) => void,
  handleClose: () => void
) => {
  await ensureSnapInstalled();
  try {
    const newSnapAddresses = await getAddresses();

    onImportAccounts(newSnapAddresses);
    localStorage.setItem(
      "fromSnap_addresses",
      JSON.stringify(newSnapAddresses)
    );
    handleClose();
  } catch (error) {
    console.log(error);
  }
};

// regex to check if this string is a valid eth address
export const isValidEthereumAddress = (address: string) =>
  /^0x[a-fA-F0-9]{40}$/.test(address);
