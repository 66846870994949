import styled from "styled-components";
import { Box } from "@mui/material";

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
  background-color: #1e1e1e;
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;

  @media (max-width: 600px) {
    width: 95%;
    max-width: none;
    padding: 20px;
  }
`;

export const HorizontalLine = styled("hr")({
  border: "1px solid #ccc",
  width: "75%",
  margin: "16px auto",
});

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

export const Tag = styled.span`
  background-color: black;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BottomRightButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 16px;
  right: 16px;
`;
