import React, { useState } from "react";
import { IconButton, Modal, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { resolveENS } from "../utils";
import {
  ModalBox,
  Header,
  Logo,
  Section,
  ButtonContainer,
  ErrorMessage,
  StyledTextField,
  CustomButton,
  BackButton,
  CustomAddButton,
} from "./StyleAddAddress";
import {
  handleImportMnemonic,
  handleGetAddressFromSnap,
  isValidEthereumAddress,
} from "../utils/snapUtils";

interface AddAddressModalProps {
  open: boolean;
  handleClose: () => void;
  handleAddAddress: (address: string) => void;
  addresses: { watchOnly: string[]; fromSnap?: string[] } | null;
  onImportAccounts: (addresses: string[]) => void;
  handleNewWatchOnlyAddress: (address: string) => void;
}

const AddAddressModal: React.FC<AddAddressModalProps> = ({
  open,
  addresses,
  handleClose,
  handleAddAddress,
  onImportAccounts,
  handleNewWatchOnlyAddress,
}) => {
  const [address, setAddress] = useState("");
  const [showAddressInput, setShowAddressInput] = useState(false);
  const [invalidAddress, setInvalidAddress] = useState<boolean>(false);

  const handleAddViewAddress = async () => {
    let resolvedAddress: string | null = address;

    // Check if the address is an ENS name and resolve it
    if (address.endsWith(".eth")) {
      resolvedAddress = await resolveENS(address);
      if (!resolvedAddress) {
        setInvalidAddress(true);
        setTimeout(() => {
          setInvalidAddress(false);
        }, 3000);
        return;
      }
    }

    if (!addresses || !isValidEthereumAddress(resolvedAddress)) {
      setInvalidAddress(true);
      setTimeout(() => {
        setInvalidAddress(false);
      }, 3000);
      return;
    }

    const addressExists =
      addresses.watchOnly.includes(resolvedAddress) ||
      (addresses?.fromSnap || []).includes(resolvedAddress);
    if (!addressExists && resolvedAddress) {
      handleNewWatchOnlyAddress(resolvedAddress);
      handleClose();
    }
    if (addressExists) {
      setAddress("");
      handleClose();
      return;
    }
    handleAddAddress(resolvedAddress);
    setAddress("");
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalBox>
        <Header>
          <Typography variant="h6" component="h2" gutterBottom>
            Log in or add an address
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </Header>
        <Logo src="/favicon.png" alt="Logo" />

        {!showAddressInput ? (
          <Section>
            <ButtonContainer>
              <CustomButton
                onClick={() =>
                  handleImportMnemonic(onImportAccounts, handleClose)
                }
              >
                <span className="icon" />
                🔐 Import a mnemonic
                <span className="text"></span>
              </CustomButton>

              <CustomButton
                onClick={() =>
                  handleGetAddressFromSnap(onImportAccounts, handleClose)
                }
              >
                <span className="icon"> 🦊 Connect Snap</span>
                <span className="text"></span>
              </CustomButton>

              <CustomButton onClick={() => setShowAddressInput(true)}>
                <span className="icon">👁️ Watch Address</span>
                <span className="text"></span>
              </CustomButton>
            </ButtonContainer>
          </Section>
        ) : (
          <Section>
            <StyledTextField
              fullWidth
              variant="outlined"
              label="Input Address or ENS"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              margin="normal"
            />
            <BackButton
              variant="text"
              onClick={() => setShowAddressInput(false)}
            >
              <ArrowBackIcon style={{ marginRight: "8px" }} />
              Back
            </BackButton>
            <CustomAddButton
              variant="contained"
              onClick={handleAddViewAddress}
              disabled={!address}
            >
              Add
            </CustomAddButton>
            {invalidAddress && (
              <ErrorMessage variant="body2" gutterBottom>
                Invalid address format. Please enter a valid Ethereum address.
              </ErrorMessage>
            )}
          </Section>
        )}
      </ModalBox>
    </Modal>
  );
};

export default AddAddressModal;
