import React, { useState, useEffect } from "react";
import { getIpfsData } from "../utils/getIpfsData";
import { getClaimableAmount } from "../utils/getClaimableAmount";
import { Airdrop, Tier } from "../interfaces";

export const useEligibilityData = (
  app: Airdrop,
  addresses: { watchOnly: string[]; fromSnap?: string[] | null }
) => {
  const [claimableAmounts, setClaimableAmounts] = useState<{
    [address: string]: { amount: string; tier: string; watchOnly: boolean };
  }>({});
  const [claimableAmountsSetup, setClaimableAmountsSetup] =
    useState<boolean>(false);
  const [notEligible, setNotEligible] = useState<string[]>([]);
  const [tier, setTier] = useState<Tier[]>([]);

  useEffect(() => {
    fetchEligibilityData(
      app,
      addresses,
      setClaimableAmounts,
      setNotEligible,
      setClaimableAmountsSetup,
      setTier
    );
  }, [app, addresses]);

  return {
    claimableAmounts,
    claimableAmountsSetup,
    notEligible,
    tier,
    setClaimableAmountsSetup,
  };
};

const fetchEligibilityData = async (
  app: Airdrop,
  addresses: { watchOnly: string[]; fromSnap?: string[] | null },
  setClaimableAmounts: React.Dispatch<
    React.SetStateAction<{
      [address: string]: { amount: string; tier: string; watchOnly: boolean };
    }>
  >,
  setNotEligible: React.Dispatch<React.SetStateAction<string[]>>,
  setClaimableAmountsSetup: React.Dispatch<React.SetStateAction<boolean>>,
  setTier: React.Dispatch<React.SetStateAction<Tier[]>>
) => {
  if (!addresses) return;

  const ipfsData = await getIpfsData(app);
  const eligibility = getClaimableAmount(addresses, ipfsData);
  const claimableAmount = eligibility.eligible;
  const notEligible = eligibility.notEligible;

  setTier(ipfsData.data);
  setClaimableAmounts(claimableAmount);
  setNotEligible(notEligible);
  setClaimableAmountsSetup(true);
};
